import React from "react"

import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"

export default () => {
  return (
    <Layout title="contact">
        <ContactForm></ContactForm>
    </Layout>
  )
}
