import React from "react"
import styled from "styled-components"

import colors from "../styles/colors"

const StyledForm = styled.form`
  width: 100%;
  margin-top: 6rem;
  transition: 0.2s ease-out all;

  label {
    font-size: 2rem;
  }

  @media only screen and (min-width: 900px) {
    width: 75%;
  }

  @media only screen and (min-width: 1600px) {
    width: 50%;
  }

  @media only screen and (max-width: 500px){
    margin-top: 0;
  }
`

const Centered = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? `column` : `row`)};
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button`
  display: inline-block;
  padding: 1rem 2rem;
  min-width: 10rem;
  background-color: ${colors.grey};
  color: ${colors.white};
  border: none;
  transition: 0.2s ease all;

  :hover {
    cursor: pointer;
    background-color: ${colors.black};
    color: ${colors.white};
  }
`

const FormInput = styled.input`
  display: block;
  border: none;
  width: 100%;
  font-size: 2.4rem;
  padding: 1rem;
  transition: 0.2s ease all;
  background-color: ${colors.lightgrey};

  :focus {
    outline: none;
    border-bottom: 3px solid ${colors.valid};
  }

  :focus:invalid {
    outline: none;
    border-bottom: 3px solid ${colors.invalid};
  }
`

const FormTextArea = styled.textarea`
  display: block;
  border: none;
  width: 100%;
  font-size: 2rem;
  padding: 1rem;
  transition: 0.2s ease all;
  resize: none;
  background-color: ${colors.lightgrey};

  :focus {
    outline: none;
    border-bottom: 3px solid ${colors.valid};
  }

  :focus:invalid {
    outline: none;
    border-bottom: 3px solid ${colors.invalid};
  }
`

const FormSubmit = StyledButton.withComponent("input")

const FormGroup = styled.div`
  :not(:last-child) {
    margin-bottom: 2rem;
  }
`

const ContactForm = () => {
  return (
    <Centered>
      <StyledForm
        action="https://formspree.io/osborn.ali@gmail.com"
        method="POST"
      >
        <FormGroup>
          <label htmlFor="name">name</label>
          <FormInput type="text" name="name" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="_replyto">email</label>
          <FormInput type="email" name="_replyto" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="body">message</label>
          <FormTextArea type="text" name="body" rows="6" required />
        </FormGroup>

        <FormGroup>
          <FormSubmit type="submit" value="Send Message" />
        </FormGroup>
      </StyledForm>
    </Centered>
  )
}

export default ContactForm
